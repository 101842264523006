import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { HeadingContainer, Text } from '../components/common/typography'
import AppLayout from '../components/layouts/appLayout'
import GatsbyImage from 'gatsby-image'
import { BLACK_COLOR_RGB, PRIMARY_COLOR } from '../components/common/color'
import bp from '../components/common/breakpoints'
import SEO from '../components/seo'
import arrowIcon from '../images/teams/arrow_icon.png'
import ModalServiceWB from '../components/ServiceModal/ModalServiceWB'

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'image'
    'headings'
    'text';
  grid-template-columns: 1fr;
  height: 50%;
  padding-bottom: 2rem;

  ${bp[1]} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'image image'
      'headings text';
  }

  ${bp[2]} {
    grid-template-columns: 3fr 2fr;
  }
`

const ImageContainer = styled.div`
  grid-area: image;
`

const HeadingsContainer = styled.div`
  grid-area: headings;
  justify-self: start;
  align-self: center;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;

  ${bp[1]} {
    width: initial;
    justify-self: center;
  }
`

const TextContainer = styled.div`
  grid-area: text;
  justify-self: center;
  align-self: center;
  padding: 1rem 2rem;
  box-sizing: border-box;
  line-height: 1.3;
`

const Heading = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--secondary-font);
  text-transform: uppercase;
`

const SubHeading = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--secondary-font);
  color: ${PRIMARY_COLOR};
  margin-bottom: 0;

  ${bp[1]} {
    margin: initial;
  }
`

const TextHeading = styled.h5`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--secondary-font);
  color: ${BLACK_COLOR_RGB(0.4)};
  margin: 0 0 0.5rem;

  ${bp[1]} {
    margin: 1rem 0;
  }
`

const ArrowButton = styled.img`
  position:relative;
  height: 10vw;
  width: 10vw;
  top: -20vw;
  left: 60vw;
  cursor: pointer;
  ${bp[4]} {
    top: -18vw;
    left: 55vw;
  }
`

const TeamsPage = ({ data }) => {
  const [show, setShow] = useState(false)

  const openModal = () => {
    setShow(true)
  }
  return (
    <AppLayout>
      <SEO
        title="Teams"
        canonical="Teams"
        description="Remote software teams, Nearshore development teams, Staff augmentation"
      />
      <ModalServiceWB
        show={show}
        setShow={setShow}
      />
      {/* Wrapping multiple elements within a fragment */}
      <>
        <ImageContainer>
          <div style={{ marginBottom: '-8vw' }}>
            <GatsbyImage fluid={data['WB1'].fluid} />
            <ArrowButton src={arrowIcon} onClick={() => openModal()} />
          </div>
        </ImageContainer>
        <ImageContainer>
          {['WB2', 'WB3'].map((imagename) => (
            <div style={{ marginBottom: '20px' }} key={imagename}>
              <GatsbyImage fluid={data[imagename].fluid} />
            </div>
          ))}
        </ImageContainer>
        <HeadingContainer
          title="Your remote team, but... Closer"
          subtitle="Our DNA"
        />
        <Container>
          <ImageContainer>
            {[
              'alioTeamComplete',
              'alfonso',
              'alioTeamGirls',
              'alioTeamMen',
              'alioTeamParkA',
              'alioTeamParkB',
              'alioTeamStairs',
            ].map((imagename) => (
              <div style={{ marginBottom: '10px' }} key={imagename}>
                <GatsbyImage fluid={data[imagename].fluid} />
              </div>
            ))}
          </ImageContainer>
        </Container>
      </>
    </AppLayout>
  )
}  

export const query = graphql`
  query {
    teamsBackground: imageSharp(
      fluid: { originalName: { eq: "teams-background.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alfonso: imageSharp(fluid: { originalName: { eq: "Alfonso.jpg" } }) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamComplete: imageSharp(
      fluid: { originalName: { eq: "AlioTeamComplete.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamGirls: imageSharp(
      fluid: { originalName: { eq: "AlioTeamGirls.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamMen: imageSharp(
      fluid: { originalName: { eq: "AlioTeamMen.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamParkA: imageSharp(
      fluid: { originalName: { eq: "AlioTeamParkA.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamParkB: imageSharp(
      fluid: { originalName: { eq: "AlioTeamParkB.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 520) {
        ...GatsbyImageSharpFluid
      }
    }
    alioTeamStairs: imageSharp(
      fluid: { originalName: { eq: "AlioTeamStairs.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1200, maxHeight: 800) {
        ...GatsbyImageSharpFluid
      }
    }
    WB1: imageSharp(
      fluid: { originalName: { eq: "WB1.png" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1920, maxHeight: 1080) {
        ...GatsbyImageSharpFluid
      }
    }
    WB2: imageSharp(
      fluid: { originalName: { eq: "WB2.png" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1920, maxHeight: 1080) {
        ...GatsbyImageSharpFluid
      }
    }
    WB3: imageSharp(
      fluid: { originalName: { eq: "WB3.png" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1920, maxHeight: 1080) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default TeamsPage
